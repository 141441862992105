import { template as template_08c1ef4869c74a0882f69e286a93e6a5 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
export default class DeferredRender extends Component {
    @tracked
    shouldRender = false;
    constructor(){
        super(...arguments);
        runAfterFramePaint(()=>(this.shouldRender = true));
    }
    static{
        template_08c1ef4869c74a0882f69e286a93e6a5(`
    {{#if this.shouldRender}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
