import { template as template_897b9df3e91e4c1992bd06efc4a14aff } from "@ember/template-compiler";
const FKControlMenuContainer = template_897b9df3e91e4c1992bd06efc4a14aff(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
