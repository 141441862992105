import { template as template_09643624123542399e0f8ab725624b67 } from "@ember/template-compiler";
const FKLabel = template_09643624123542399e0f8ab725624b67(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
